<template>
    <div
      class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-8892e16"
      data-id="8892e16"
      data-element_type="column"
    >
      <div class="elementor-widget-wrap elementor-element-populated" id="innerProductsPage">


        <div
          class="elementor-element elementor-element-14606e6 elementor-widget elementor-widget-text-editor"
          data-id="14606e6"
          data-element_type="widget"
          data-widget_type="text-editor.default"
        >
        <div
          class="elementor-element elementor-element-c88df83 elementor-widget elementor-widget-mae-post-content-title"
          data-id="c88df83"
          data-element_type="widget"
          data-widget_type="mae-post-content-title.default"
        >
          <div class="elementor-widget-container">
            <div class="hentry">
              <h1 class="post-title">{{ $t(`productPage.${name}.title`)}}</h1>
            </div>
          </div>
        </div>
          <div class="elementor-widget-container">
            <p v-show="muchkas"> {{ $t(`productPage.${name}.s1`)}}</p>
            <p v-show="!muchkas">
              {{ $t(`productPage.${name}.s1`)}}
              <br/>
              {{ $t(`productPage.${name}.s2`)}}
              <br/>
              {{ $t(`productPage.${name}.s3`)}}
              <br/>
              <br/>
              {{ $t(`productPage.${name}.s4`)}}
              <br/>
              
              {{ $t(`productPage.${name}.s5`)}}
              <br/>
              {{ $t(`productPage.${name}.s6`)}}
              <br/>
              {{ $t(`productPage.${name}.s7`)}}
              <br/>
              {{ $t(`productPage.${name}.s8`)}}
              <br/>
              {{ $t(`productPage.${name}.s9`)}}
              <br/>
            </p>
            <!-- <p v-show="!muchkas">
              {{ $t(`productPage.${name}.s4`)}}
              <ul v-show="!small">
                <li>{{ $t(`productPage.${name}.s5`)}}</li>
                <li>{{ $t(`productPage.${name}.s6`)}}</li>
                <li>{{ $t(`productPage.${name}.s7`)}}</li>
                <li>{{ $t(`productPage.${name}.s8`)}}</li>
                <li>{{ $t(`productPage.${name}.s9`)}}</li>
              </ul>
            </p> -->
          </div>
        </div>
        <div
          class="elementor-element elementor-element-3c919e0 shake-full elementor-widget elementor-widget-mae-post-featured_image"
          data-id="3c919e0"
          data-element_type="widget"
          data-widget_type="mae-post-featured_image.default"
        >
          <div class="elementor-widget-container">
            <div class="post-media" id="productImg">
              <img
                width="770"
                height="420"
                :src="require(`../../public/img/krypu/${name}.png`)" :alt="name"
                :class="[
                    'attachment-full size-full wp-post-image lazy',
                    { bigImg:  smallImg},
                  ]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data(){
      return{ 
        name: this.flour,
        small: this.flourSmall ,
        muchkas: this.muchka,
        smallImg: this.size
      }
    },
    props: {
      flour: String,
      flourSmall: Boolean,
      muchka: Boolean,
      size: Boolean
  }
  };
  </script>
  
  <style lang="scss" scoped></style>
  