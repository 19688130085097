import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue'
import Flour from './views/Flour.vue'
import Oil from './views/Oil.vue'
import Pasta from './views/Pasta.vue'
import Cereals from './views/Cereals.vue'
Vue.use(Router);


  let router = new Router({
    mode: 'history',
    scrollBehavior() {
      return { x: 0, y: 0 };
    },  
    routes: [
      {
        path: '/',
        name: 'home',
        component: Home,
      },
      {
        path: "/flour",
        name: "flour",
        component: Flour,
      },
      {
        path: "/pasta",
        name: "pasta",
        component: Pasta,
      },
      {
        path: "/cereals",
        name: "cereals",
        component: Cereals,
      },
      {
        path: "/oil",
        name: "oil",
        component: Oil,
      }
      
    ],
  });
  

  export default router;