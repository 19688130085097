import Vue from 'vue'
import App from './App.vue'
import router from './router';
import i18n from './i18n';

Vue.config.productionTip = false

Object.defineProperty(Vue.prototype, '$locale', {
  get: function() {
    return i18n.locale;
  },
  set: function(locale) {
    i18n.locale = locale;
  },
});


new Vue({
  i18n,
  router,
  render: h => h(App),
}).$mount('#app');
