<template>
  <article id="contacts" class="page-content post-2393 page type-page status-publish hentry">
    <div
      data-elementor-type="wp-page"
      data-elementor-id="2393"
      class="elementor elementor-2393"
    >
      <section
        class="elementor-section elementor-top-section elementor-element elementor-element-22d5ca0 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="22d5ca0"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-97fba15"
            data-id="97fba15"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="elementor-element elementor-element-2a28d27 elementor-widget elementor-widget-spacer"
                data-id="2a28d27"
                data-element_type="widget"
                data-widget_type="spacer.default"
              >
                <div class="elementor-widget-container">
                  <div class="elementor-spacer">
                    <div class="elementor-spacer-inner"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="elementor-section elementor-top-section elementor-element elementor-element-a2bda3d elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="a2bda3d"
        data-element_type="section"
        data-settings='{"background_background":"classic"}'
      >
        <div class="elementor-container elementor-column-gap-no">
          <div
            class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4fc3294"
            data-id="4fc3294"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <section
                class="elementor-section elementor-inner-section elementor-element elementor-element-3e4b07a elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="3e4b07a"
                data-element_type="section"
                data-settings='{"background_background":"classic"}'
              >
                <div class="elementor-container elementor-column-gap-no">
                  <div
                    class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-a646cba"
                    data-id="a646cba"
                    data-element_type="column"
                  >
                    <div
                      class="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        class="elementor-element elementor-element-ef1fb6e map-light elementor-widget elementor-widget-google_maps"
                        data-id="ef1fb6e"
                        data-element_type="widget"
                        data-widget_type="google_maps.default"
                        style="height: 100%;"
                        >
                        <div class="elementor-widget-container" style="height: 100%;">
                          <div class="elementor-custom-embed" style="height: 100%;">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2605.002517186722!2d28.47838231586356!3d49.23844148182495!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x472d5b6626259ee5%3A0xdaa03992f5bd6d46!2z0YPQuy4g0JrQuNC10LLRgdC60LDRjywgMTYsINCS0LjQvdC90LjRhtCwLCDQktC40L3QvdC40YbQutCw0Y8g0L7QsdC70LDRgdGC0YwsIDIxMDAw!5e0!3m2!1sru!2sua!4v1669051117221!5m2!1sru!2sua" width="600" height="740px" style="border:0;height: 100%;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-b191b1f"
                    data-id="b191b1f"
                    data-element_type="column"
                    data-settings='{"background_background":"classic"}'
                  >
                    <div
                      class="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        class="elementor-element elementor-element-94a8dd9 elementor-widget elementor-widget-spacer"
                        data-id="94a8dd9"
                        data-element_type="widget"
                        data-widget_type="spacer.default"
                      >
                        <div class="elementor-widget-container">
                          <div class="elementor-spacer">
                            <div class="elementor-spacer-inner"></div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-f890cfb elementor-widget elementor-widget-metform"
                        data-id="f890cfb"
                        data-element_type="widget"
                        data-widget_type="metform.default"
                      >
                        <div class="elementor-widget-container">
                          <div
                            id="mf-response-props-id-1570"
                            data-previous-steps-style=""
                            data-editswitchopen=""
                            data-response_type="alert"
                            data-erroricon="fas fa-exclamation-triangle"
                            data-successicon="fas fa-check"
                            data-messageposition="top"
                            class="mf-scroll-top-no"
                          >
                            <div
                              class="formpicker_warper formpicker_warper_editable"
                              data-metform-formpicker-key="1570"
                            >
                              <div class="elementor-widget-container">
                                <div
                                  id="metform-wrap-f890cfb-1570"
                                  class="mf-form-wrapper"
                                ></div>

                                <form class="metform-form-content" @submit.prevent="sendForm">
                                  <div class="metform-form-main-wrapper">
                                    <div
                                      data-elementor-type="wp-post"
                                      key="2"
                                      data-elementor-id="1570"
                                      class="elementor elementor-1570"
                                    >
                                      <section
                                        class="elementor-section elementor-top-section elementor-element elementor-element-5c66b2c elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                        data-id="5c66b2c"
                                        data-element_type="section"
                                      >
                                        <div
                                          class="elementor-container elementor-column-gap-default"
                                        >
                                          <div
                                            class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d51892c"
                                            data-id="d51892c"
                                            data-element_type="column"
                                          >
                                            <div
                                              class="elementor-widget-wrap elementor-element-populated"
                                            >
                                              <div
                                                class="elementor-element elementor-element-8337c80 heading-default elementor-widget elementor-widget-mae-headings"
                                                data-id="8337c80"
                                                data-element_type="widget"
                                                data-widget_type="mae-headings.default"
                                              >
                                                <div
                                                  class="elementor-widget-container"
                                                >
                                                  <div
                                                    class="master-heading sep-none"
                                                  >
                                                    <div class="pre-heading">
                                                      <span class="line"
                                                        ><span
                                                          class="inner"
                                                        ></span></span
                                                      > {{ $t("contacts.title") }}
                                                    </div>

                                                    <h2 class="main-heading contacts-heading">
                                                      {{ $t("contacts.subtitle1") }}
                                                      <br/>
                                                      {{ $t("contacts.subtitle2") }}
                                                    </h2>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                class="elementor-element elementor-element-3667862 elementor-widget__width-initial elementor-widget elementor-widget-mf-listing-fname"
                                                data-id="3667862"
                                                data-element_type="widget"
                                                data-settings='{"mf_input_name":"mf-listing-fname"}'
                                                data-widget_type="mf-listing-fname.default"
                                              >
                                                <div
                                                  class="elementor-widget-container"
                                                >
                                                  <div class="mf-input-wrapper">
                                                    <input
                                                      type="text"
                                                      v-model="name"
                                                      class="mf-input"
                                                      id="mf-input-text-3667862"
                                                      name="mf-listing-fname"
                                                      :placeholder=" $t('contacts.name') "
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                class="elementor-element elementor-element-7752390 elementor-widget__width-initial elementor-widget elementor-widget-mf-email"
                                                data-id="7752390"
                                                data-element_type="widget"
                                                data-settings='{"mf_input_name":"mf-email"}'
                                                data-widget_type="mf-email.default"
                                              >
                                                <div
                                                  class="elementor-widget-container"
                                                >
                                                  <div class="mf-input-wrapper">
                                                    <input
                                                      type="email"
                                                      v-model="email"
                                                      defaultValue=""
                                                      class="mf-input"
                                                      id="mf-input-email-7752390"
                                                      name="mf-email"
                                                      :placeholder=" $t('contacts.email') "
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                class="elementor-element elementor-element-117399d elementor-widget elementor-widget-mf-textarea"
                                                data-id="117399d"
                                                data-element_type="widget"
                                                data-settings='{"mf_input_name":"mf-textarea"}'
                                                data-widget_type="mf-textarea.default"
                                              >
                                                <div
                                                  class="elementor-widget-container"
                                                >
                                                  <div class="mf-input-wrapper">
                                                    <textarea
                                                      class="mf-input mf-textarea"
                                                      v-model="mes"
                                                      id="mf-input-text-area-117399d"
                                                      name="mf-textarea"
                                                      :placeholder=" $t('contacts.message') "
                                                      cols="60"
                                                      rows="10"
                                                    ></textarea>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                class="elementor-element elementor-element-dd668f5 mf-btn--left elementor-widget elementor-widget-mf-button"
                                                data-id="dd668f5"
                                                data-element_type="widget"
                                                data-widget_type="mf-button.default"
                                              >
                                                <div
                                                  class="elementor-widget-container"
                                                >
                                                  <div
                                                    class="mf-btn-wraper"
                                                    data-mf-form-conditional-logic-requirement=""
                                                  >
                                                    <button
                                                      type="submit"
                                                      class="metform-btn metform-submit-btn"
                                                      id=""
                                                    >
                                                      <span
                                                        >{{ $t("contacts.send") }}</span
                                                      >
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </section>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-77c7fa3 elementor-widget elementor-widget-spacer"
                        data-id="77c7fa3"
                        data-element_type="widget"
                        data-widget_type="spacer.default"
                      >
                        <div class="elementor-widget-container">
                          <div class="elementor-spacer">
                            <div class="elementor-spacer-inner"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        <div
          class="elementor-element elementor-element-2a28d27 elementor-widget elementor-widget-spacer"
          data-id="2a28d27"
          data-element_type="widget"
          data-widget_type="spacer.default"
        >
          <div class="elementor-widget-container">
            <div class="elementor-spacer">
              <div class="elementor-spacer-inner"></div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </article>
</template>

<script>
import axios from 'axios'
export default {
  data(){
    return {
      email:  "",
      mes: "",
      name: "",
    }

    },
      methods: {
        sendForm(){
          (this.name !="" && this.email != "") ? 
            axios({
              method: 'post',
              url: `https://api.telegram.org/bot5817092867:AAE2DjM5dz0Dk26gX1LoqrhNQSEmzNVPEes/sendMessage?chat_id=-975944945&parse_mode=html&text=Name:${this.name} -  Email:${this.email} - Message: ${this.mes}`,
              data: {
                name: this.name,
              }
            },  alert("Thank you for contacting, our manager will contact you"))  : alert("Please fill in the fields");
         }
      
  }
};
</script>

<style lang="scss" scoped></style>
