<template>
  <div
    data-elementor-type="wp-page"
    data-elementor-id="1710"
    class="elementor elementor-1710"
  >
    <section
      class="elementor-section elementor-top-section elementor-element elementor-element-e97c40d elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-id="e97c40d"
      data-element_type="section"
    >
      <div class="elementor-container elementor-column-gap-default">
        <div
          class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-93ad820"
          data-id="93ad820"
          data-element_type="column"
        >
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="elementor-element elementor-element-4e25fd8 align-center counter-custom-1 icon-separator icon-position-top elementor-widget elementor-widget-mae-counter"
              data-id="4e25fd8"
              data-element_type="widget"
              data-widget_type="mae-counter.default"
            >
              <div class="elementor-widget-container">
                <div class="master-counter" data-format="separator">
                  <div class="icon-wrap">
                    <div class="master-icon">
                      <i aria-hidden="true" class="agi agi-wheat-sack"></i>
                    </div>
                  </div>

                  <div class="inner">
                    <div class="number-wrap">
                      <span
                        class="number"
                        data-to="20"
                        data-time="1"
                      ></span><span>+</span>
                    </div>

                    <span class="title">{{ $t("advantages.first") }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-adc9bd2"
          data-id="adc9bd2"
          data-element_type="column"
        >
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="elementor-element elementor-element-b5789da align-center counter-custom-1 icon-separator icon-position-top elementor-widget elementor-widget-mae-counter"
              data-id="b5789da"
              data-element_type="widget"
              data-widget_type="mae-counter.default"
            >
              <div class="elementor-widget-container">
                <div class="master-counter" data-format="separator">
                  <div class="icon-wrap">
                    <div class="master-icon">
                      <i aria-hidden="true" class="agi agi-farmer-1"></i>
                    </div>
                  </div>

                  <div class="inner">
                    <div class="number-wrap">
                      <span
                        class="number"
                        data-to="110"
                        data-time="1"
                      ></span>
                    </div>

                    <span class="title">{{ $t("advantages.second") }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-8d6a2b1"
          data-id="8d6a2b1"
          data-element_type="column"
        >
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="elementor-element elementor-element-bca1f85 align-center counter-custom-1 icon-separator icon-position-top elementor-widget elementor-widget-mae-counter"
              data-id="bca1f85"
              data-element_type="widget"
              data-widget_type="mae-counter.default"
            >
              <div class="elementor-widget-container">
                <div class="master-counter" data-format="separator">
                  <div class="icon-wrap">
                    <div class="master-icon">
                      <i aria-hidden="true" class="agi agi-field "></i>
                    </div>
                  </div>

                  <div class="inner">
                    <div class="number-wrap">
                      <span
                        class="number"
                        data-to="13"
                        data-time="1"
                      ></span>
                    </div>

                    <span class="title">{{ $t("advantages.third") }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-ad805fa"
          data-id="ad805fa"
          data-element_type="column"
        >
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="elementor-element elementor-element-4d6117a align-center counter-custom-1 icon-separator icon-position-top elementor-widget elementor-widget-mae-counter"
              data-id="4d6117a"
              data-element_type="widget"
              data-widget_type="mae-counter.default"
            >
              <div class="elementor-widget-container">
                <div class="master-counter" data-format="separator">
                  <div class="icon-wrap">
                    <div class="master-icon">
                      <i aria-hidden="true" class="agi agi-farmer-2"></i>
                    </div>
                  </div>

                  <div class="inner">
                    <div class="number-wrap">
                      <span
                        class="number"
                        data-to="174"
                        data-time="1"
                      ></span>
                    </div>

                    <span class="title">{{ $t("advantages.last") }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
