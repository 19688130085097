<template>
  <div
    id="cooperation"
    data-elementor-type="wp-page"
    data-elementor-id="1274"
    class="elementor elementor-1274"
  >
    <section
      class="elementor-section elementor-top-section elementor-element elementor-element-615fd60 elementor-section-full_width elementor-section-stretched elementor-section-height-default elementor-section-height-default"
      data-id="615fd60"
      data-element_type="section"
      data-settings='{"stretch_section":"section-stretched"}'
    >
      <div class="elementor-container elementor-column-gap-no">
        <div
          class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-bd3ac3a"
          data-id="bd3ac3a"
          data-element_type="column"
        >
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="elementor-element elementor-element-bf2f9b0 elementor-widget elementor-widget-spacer"
              data-id="bf2f9b0"
              data-element_type="widget"
              data-widget_type="spacer.default"
            >
              <div class="elementor-widget-container">
                <div class="elementor-spacer">
                  <div class="elementor-spacer-inner"></div>
                </div>
              </div>
            </div>
            <section
              class="elementor-section elementor-inner-section elementor-element elementor-element-6621fb7 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="6621fb7"
              data-element_type="section"
            >
              <div class="elementor-container elementor-column-gap-default">
                <div
                  class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-7678b9a"
                  data-id="7678b9a"
                  data-element_type="column"
                >
                  <div
                    class="elementor-widget-wrap elementor-element-populated"
                  >
                    <div
                      class="elementor-element elementor-element-feb0c1a image-width-fit elementor-widget-tablet__width-auto elementor-widget elementor-widget-mae-gallery-stack"
                      data-id="feb0c1a"
                      data-element_type="widget"
                      data-widget_type="mae-gallery-stack.default"
                    >
                      <div class="elementor-widget-container">
                        <div class="master-gallery-stack">
                          <div
                            class="master-fancy-image elementor-repeater-item-b23048b"
                            data-top="0px"
                            data-calcHeight="no"
                          >
                            <div class="image-wrap">
                              <img
                                width="470"
                                height="514"
                                src="../../../public/img/about.jpg"
                                class="attachment-full size-full lazy"
                                alt="about"
                              />
                            </div>
                          </div>
                          <!-- <div
                            class="master-text elementor-repeater-item-c9a90c6 default"
                          >
                            <div class="inner">
                              <div class="cta-info">
                                <span class="text">{{ $t("menu.call") }}</span>
                                <a class="master-cta" href="tel:0674322378">
                                  <span class="content-wrap">
                                    (067) 432-23-78
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-9f53db1"
                  data-id="9f53db1"
                  data-element_type="column"
                >
                  <div
                    class="elementor-widget-wrap elementor-element-populated"
                  >
                    <div
                      class="elementor-element elementor-element-7b23578 heading-default elementor-widget elementor-widget-mae-headings"
                      data-id="7b23578"
                      data-element_type="widget"
                      data-widget_type="mae-headings.default"
                    >
                      <div class="elementor-widget-container">
                        <div class="master-heading sep-none">
                          <div class="pre-heading">
                            <span class="line"><span class="inner"></span></span
                            >{{ $t("purchase.purchase") }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="elementor-element elementor-element-020f969 accordion-style-1 arrow-right elementor-widget elementor-widget-mae-accordion"
                      data-id="020f969"
                      data-element_type="widget"
                      data-widget_type="mae-accordion.default"
                    >
                      <div class="elementor-widget-container">
                        <div class="master-accordions">
                          <div class="item elementor-repeater-item-ea644e5 ">
                            <div class="title purchase-content">
                              <h3>{{ $t("purchase.first") }}</h3>
                              <ul>
                                  <li>{{ $t("purchase.second.first") }}</li>
                                  <li>{{ $t("purchase.second.second") }}</li>
                                  <li>{{ $t("purchase.second.third") }}</li>
                                  <li>{{ $t("purchase.second.last") }}</li>
                                </ul>
                            </div>

                          </div>
                          <div
                            class="item elementor-repeater-item-1a284e0 active"
                          >
                            <!-- <div class="title">
                              <h3>{{ $t("purchase.third.first") }}</h3>
                            </div> -->

                            <div class="content">
                              <p>
                                {{ $t("purchase.third.second") }}
                                
                                <a class="master-cta" href="tel:0674320184">
                                  <span class="content-wrap">
                                    (067) 432-01-84
                                  </span>
                                </a>
                                <br />
                                <span style="display: block;margin-bottom: 20px;">{{ $t("purchase.third.last") }}</span>
                              </p>
                              <div
                      class="elementor-element elementor-element-b2d67fa elementor-widget__width-auto elementor-widget elementor-widget-mae-button"
                      data-id="b2d67fa"
                      data-element_type="widget"
                      data-widget_type="mae-button.default"
                    >
                      <div class="elementor-widget-container">
                        <a
                          class="master-button btn-accent icon-none medium btn-hover-2"
                          href="#contacts"
                        >
                          <span class="inner">
                            <span class="content-base">
                              <span class="text">{{ $t("btns.order") }}</span>
                            </span>

                            <span class="content-hover">
                              <span class="text">{{ $t("btns.order") }}</span>
                            </span>
                          </span>

                          <span class="bg-hover"></span>
                        </a>
                      </div>
                    </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div
              class="elementor-element elementor-element-1ee5bb2 elementor-widget elementor-widget-spacer"
              data-id="1ee5bb2"
              data-element_type="widget"
              data-widget_type="spacer.default"
            >
              <div class="elementor-widget-container">
                <div class="elementor-spacer">
                  <div class="elementor-spacer-inner"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
