<template>
    <div id="product-page">
      <div
        id="featured-title"
        class="clearfix centered lazy"
      >
        <div class="agrios-container clearfix">
          <div class="inner-wrap">
            <div id="breadcrumbs">
              <div class="breadcrumbs-inner">
                <div class="breadcrumb-trail">
                  <router-link class="home" to="/">{{ $t("menu.home") }}</router-link>
                <span>{{ $t("products.titleOil") }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /#featured-title -->
  
      <div id="main-content" class="site-main clearfix productPage" style="">
        <div id="content-wrap" class="agrios-container">
          <div id="site-content" class="site-content clearfix">
            <div id="inner-content" class="inner-content-wrap">
              <div
                data-elementor-type="wp-post"
                data-elementor-id="2466"
                class="elementor elementor-2466"
              >
  
                <section
                  class="elementor-section elementor-top-section elementor-element elementor-element-f50ea9a elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="f50ea9a"
                  data-element_type="section"
                >
                  <div class="elementor-container elementor-column-gap-default">
                    <div
                      class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-14cf8a7 elementor-hidden-tablet elementor-hidden-mobile"
                      data-id="14cf8a7"
                      data-element_type="column"
                    >
                      <div
                        class="elementor-widget-wrap elementor-element-populated"
                      >
                   
                        <section
                          class="elementor-section elementor-inner-section elementor-element elementor-element-20eeebb elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                          data-id="20eeebb"
                          data-element_type="section"
                        >
                          <div
                            class="elementor-container elementor-column-gap-default"
                          >
                            <div
                              class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-8deff70"
                              data-id="8deff70"
                              data-element_type="column"
                              data-settings='{"background_background":"classic"}'
                            >
                              <div
                                class="elementor-widget-wrap elementor-element-populated"
                              >
                                <div
                                  class="elementor-element elementor-element-de91cae align-center heading-default elementor-widget elementor-widget-mae-headings"
                                  data-id="de91cae"
                                  data-element_type="widget"
                                  data-widget_type="mae-headings.default"
                                >
                                  <div class="elementor-widget-container">
                                    <div class="master-heading sep-none">
                                      <h2 class="main-heading">{{ $t("help.title") }}</h2>
  
                                      <div class="sub-heading">
                                        {{ $t("help.subtitle") }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="elementor-element elementor-element-8995b5b elementor-widget elementor-widget-text-editor"
                                  data-id="8995b5b"
                                  data-element_type="widget"
                                  data-widget_type="text-editor.default"
                                >
                                  <div class="elementor-widget-container">
                                    <a class="phone-btn" href="tel:0674322378">(067) 432-23-78</a>
                                  </div>
                                </div>
                                <div
                                  class="elementor-element elementor-element-b56fbfc align-center elementor-widget elementor-widget-mae-button"
                                  data-id="b56fbfc"
                                  data-element_type="widget"
                                  data-widget_type="mae-button.default"
                                >
                                  <div class="elementor-widget-container">
                                    <a
                                      class="master-button btn-accent icon-none medium btn-hover-2"
                                      href="/#contacts"
                                    >
                                      <span class="inner">
                                        <span class="content-base">
                                          <span class="text"
                                            >{{ $t("help.btn") }}</span
                                          >
                                        </span>
  
                                        <span class="content-hover">
                                          <span class="text"
                                            >{{ $t("help.btn") }}</span
                                          >
                                        </span>
                                      </span>
  
                                      <span class="bg-hover"></span>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                    <OilSingle :oil ="'oil'"/>
                  </div>
                </section>
              </div>
            </div>
            <!-- /#inner-content -->
          </div>
          <!-- /#site-content -->
        </div>
        <!-- /#content-wrap -->
      </div>
    </div>
  </template>
  
  <script>
  import OilSingle from '../components/OilSingle.vue'
  export default {
    name: "Product",
    data() {
    return {
      isActive: true,
      OilCategories: [
      { name: "oil", key: 1},
    ]
    };
  },
  methods: {
    activeTab: function () {
      this.isActive = !this.isActive;
    },
  },
    components: {
      OilSingle
    },


  };
  </script>
  
  <style lang="scss" scoped></style>
  