<template>
  <div
    data-elementor-type="wp-page"
    data-elementor-id="1274"
    class="elementor elementor-1274"
  >
    <section
      class="elementor-section elementor-top-section elementor-element elementor-element-3230404 elementor-section-full_width elementor-section-stretched light-animation elementor-section-height-default elementor-section-height-default"
      data-id="3230404"
      data-element_type="section"
      data-settings='{"stretch_section":"section-stretched"}'
    >
      <div class="elementor-container elementor-column-gap-default">
        <div
          class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ce35754"
          data-id="ce35754"
          data-element_type="column"
        >
          <div class="elementor-widget-wrap elementor-element-populated">
            <section
              class="elementor-section elementor-inner-section elementor-element elementor-element-5d1b30c elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="5d1b30c"
              data-element_type="section"
            >
              <div class="elementor-container elementor-column-gap-default">
                <div
                  class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-cbdb3b8"
                  data-id="cbdb3b8"
                  data-element_type="column"
                >
                  <div
                    class="elementor-widget-wrap elementor-element-populated"
                  >
                    <div
                      class="elementor-element elementor-element-fa457aa slider-full-width slider-dots-no slider-arrows-yes arrows-pos-cc arrows-size-big elementor-widget elementor-widget-mae-slider"
                      data-id="fa457aa"
                      data-element_type="widget"
                      data-widget_type="mae-slider.default"
                    >
                      <div class="elementor-widget-container">
                        <div class="master-slider">
                          <div class="bg-wrap">
                            <div
                              class="bg elementor-repeater-item-032cee7 active"
                            ></div>
                            <div
                              class="bg elementor-repeater-item-8f878b7"
                            ></div>
                          </div>

                          <div class="content-wrap">
                            <div
                              class="slide elementor-repeater-item-032cee7 active"
                            >
                              <h1 class="title">
                                <span class="accent-color-2"> {{ $t("banner.first") }}<br />
                                 </span>
                                 {{ $t("banner.second") }}
                                <span class=""
                                  > {{ $t("banner.third") }}</span>
                                <br />
                                 {{ $t("banner.last") }}
                              </h1>
                              <div class="url-wrap">
                                <div class="slide-url url1">
                                  <a
                                    class="master-button big btn-hover-2"
                                    href="#about"
                                    ><span class="inner"
                                      ><span class="content-base">
                                        {{ $t("btns.discover") }} </span
                                      ><span class="content-hover">{{
                                        $t("menu.discover")
                                      }}</span></span
                                    ><span class="bg-hover"></span
                                  ></a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- /.content-wrap -->
                        </div>
                        <!-- /.master-slider -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
