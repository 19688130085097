<template>
  <div
    id="products"
    data-elementor-type="wp-page"
    data-elementor-id="1710"
    class="elementor elementor-1710"
  >
    <section
      class="elementor-section elementor-top-section elementor-element elementor-element-2df7e53 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-id="2df7e53"
      data-element_type="section"
    >
      <div class="elementor-container elementor-column-gap-no">
        <div
          class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6b73447"
          data-id="6b73447"
          data-element_type="column"
        >
          <div class="elementor-widget-wrap elementor-element-populated">
            <section
              class="elementor-section elementor-inner-section elementor-element elementor-element-932350c elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="932350c"
              id="productsSection"
              data-element_type="section"
            >
              <div class="elementor-container elementor-column-gap-default">
                <div
                  class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-1c07a52"
                  data-id="1c07a52"
                  data-element_type="column"
                >
                  <div
                    class="elementor-widget-wrap elementor-element-populated"
                  >
                    <div
                      class="elementor-element elementor-element-c9e03fe image-box-style-4 align-center elementor-widget elementor-widget-mae-image-box"
                      data-id="c9e03fe"
                      data-element_type="widget"
                      data-widget_type="mae-image-box.default"
                    >
                      <div class="elementor-widget-container">
                        <router-link to="flour">
                          <div class="master-image-box">
                            <h3 class="headline-2">
                              {{ $t("products.titleFlour") }}
                            </h3>
                            <div class="inner">
                              <div class="thumb productImg">
                                <div class="inner">
                                  <img
                                    
width="370" height="289"
                                    src="../../../public/img/flour.png"
                                    class="attachment-full size-full lazy product-main "
                                    alt="products"
                                  />
                                </div>
                              </div>
                              <div class="content-wrap">
                                <!-- <div class="icon-wrap">
                                <div class="master-icon">
                                  <i class="agi agi-sprout"></i>
                                </div>
                              </div> -->
                                <div class="desc">
                                  <p>
                                    {{ $t("products.flour") }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-dd1fc0a"
                  data-id="dd1fc0a"
                  data-element_type="column"
                >
                  <div
                    class="elementor-widget-wrap elementor-element-populated"
                  >
                    <div
                      class="elementor-element elementor-element-9a46860 image-box-style-4 align-center elementor-widget elementor-widget-mae-image-box"
                      data-id="9a46860"
                      data-element_type="widget"
                      data-widget_type="mae-image-box.default"
                    >
                      <div class="elementor-widget-container">
                        <router-link to="/cereals">
                          <div class="master-image-box">
                            <h3 class="headline-2">
                              {{ $t("products.titleCereals") }}
                            </h3>
                            <div class="inner">
                              <div class="thumb productImg">
                                <div class="inner">
                                  <img
                                    
width="370" height="289"
                                    src="../../../public/img/cereals.png"
                                    class="attachment-full size-full lazy product-main img-cereals-products"
                                    alt="products"
                                  />
                                </div>
                              </div>
                              <div class="content-wrap">
                                <div class="desc">
                                  <p>
                                    {{ $t("products.cereals") }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-0563917"
                  data-id="0563917"
                  data-element_type="column"
                >
                  <div
                    class="elementor-widget-wrap elementor-element-populated"
                  >
                    <div
                      class="elementor-element elementor-element-cd8129b image-box-style-4 align-center elementor-widget elementor-widget-mae-image-box"
                      data-id="cd8129b"
                      data-element_type="widget"
                      data-widget_type="mae-image-box.default"
                    >
                      <div class="elementor-widget-container">
                        <router-link to="/pasta">
                          <div class="master-image-box">
                            <h3 class="headline-2">
                              {{ $t("products.titlePasta") }}
                            </h3>
                            <div class="inner">
                              <div class="thumb productImg">
                                <div class="inner">
                                  <img
                                    
width="370" height="289"
                                    src="../../../public/img/pasta.png"
                                    class="attachment-full size-full lazy product-main "
                                    alt="products"
                                  />
                                </div>
                              </div>
                              <div class="content-wrap">
                                <div class="desc">
                                  <p>
                                    {{ $t("products.pasta") }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-0563917"
                  data-id="0563917"
                  data-element_type="column"
                >
                  <div
                    class="elementor-widget-wrap elementor-element-populated"
                  >
                    <div
                      class="elementor-element elementor-element-cd8129b image-box-style-4 align-center elementor-widget elementor-widget-mae-image-box"
                      data-id="cd8129b"
                      data-element_type="widget"
                      data-widget_type="mae-image-box.default"
                    >
                      <div class="elementor-widget-container">
                        <router-link to="/oil">
                          <div class="master-image-box">
                            <h3 class="headline-2">
                              {{ $t("products.titleOil") }}
                            </h3>
                            <div class="inner">
                              <div class="thumb productImg">
                                <div class="inner">
                                  <img
                                    
width="370" height="289"
                                    src="../../../public/img/oil.png"
                                    class="attachment-full size-full lazy product-main productOil "
                                    alt="products"
                                  />
                                </div>
                              </div>
                              <div class="content-wrap">
                                <div class="desc">
                                  <p>
                                    {{ $t("products.oil") }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
