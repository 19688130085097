<template>
  <div
    class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-8892e16"
    data-id="8892e16"
    data-element_type="column"
  >
    <div class="elementor-widget-wrap elementor-element-populated" id="innerProductsPage">

      <div
        class="elementor-element elementor-element-14606e6 elementor-widget elementor-widget-text-editor"
        data-id="14606e6"
        data-element_type="widget"
        data-widget_type="text-editor.default"
      >
      <div
        class="elementor-element elementor-element-c88df83 elementor-widget elementor-widget-mae-post-content-title"
        data-id="c88df83"
        data-element_type="widget"
        data-widget_type="mae-post-content-title.default"
      >
        <div class="elementor-widget-container">
          <div class="hentry">
            <h1 class="post-title">{{ $t(`productPage.${name}.title`)}}</h1>
          </div>
        </div>
      </div>
      <div v-if="high" class="elementor-widget-container">
          <p>
            {{ $t(`productPage.flourHigher.f1`)}}
            <br/>
            {{ $t(`productPage.flourHigher.f2`)}}
            <br/>
            {{ $t(`productPage.flourHigher.f3`)}}
            <br/>
          </p>
          <p>
            {{ $t(`productPage.flourHigher.f4`)}}
            <ul>
              <li>{{ $t(`productPage.flourHigher.f5`)}}</li>
              <li>{{ $t(`productPage.flourHigher.f6`)}}</li>
              <li>{{ $t(`productPage.flourHigher.f7`)}}</li>
              <li>{{ $t(`productPage.flourHigher.f8`)}}</li>
              <li>{{ $t(`productPage.flourHigher.f9`)}}</li>
              <li>{{ $t(`productPage.flourHigher.f10`)}}</li>
              <li>{{ $t(`productPage.flourHigher.f11`)}}</li>
            </ul>
          </p>
        </div>
        <div v-else class="elementor-widget-container">
          <p>
            {{ $t(`productPage.flourFirst.f1`)}}
            <br/>
            {{ $t(`productPage.flourFirst.f2`)}}
            <br/>
            {{ $t(`productPage.flourFirst.f3`)}}
            <br/>
          </p>
          <p>
            {{ $t(`productPage.flourFirst.f4`)}}
            <ul>
              <li>{{ $t(`productPage.flourFirst.f5`)}}</li>
              <li>{{ $t(`productPage.flourFirst.f6`)}}</li>
              <li>{{ $t(`productPage.flourFirst.f7`)}}</li>
              <li>{{ $t(`productPage.flourFirst.f8`)}}</li>
              <li>{{ $t(`productPage.flourFirst.f9`)}}</li>
              <li>{{ $t(`productPage.flourFirst.f10`)}}</li>
              <li>{{ $t(`productPage.flourFirst.f11`)}}</li>
            </ul>
          </p>
        </div>
      </div>
      <div
        class="elementor-element elementor-element-3c919e0 shake-full elementor-widget elementor-widget-mae-post-featured_image"
        data-id="3c919e0"
        data-element_type="widget"
        data-widget_type="mae-post-featured_image.default"
      >
        <div class="elementor-widget-container">
          <div class="post-media" id="productImg">
            <img
              width="770"
              height="420"
              :src="require(`../../public/img/${name}.png`)" :alt="name"
              class="attachment-full size-full wp-post-image lazy"
            />
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{ 
      name: this.flour,
      high: this.higher
    }
  },
  props: {
    flour: String,
    higher: Boolean,
}
};
</script>

<style lang="scss" scoped></style>
