<template>
  <div
    class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-8892e16"
    data-id="8892e16"
    data-element_type="column"
  >
    <div class="elementor-widget-wrap elementor-element-populated" id="innerProductsPage">


      <div
        class="elementor-element elementor-element-14606e6 elementor-widget elementor-widget-text-editor"
        data-id="14606e6"
        data-element_type="widget"
        data-widget_type="text-editor.default"
      >
      <div
        class="elementor-element elementor-element-c88df83 elementor-widget elementor-widget-mae-post-content-title"
        data-id="c88df83"
        data-element_type="widget"
        data-widget_type="mae-post-content-title.default"
      >
        <div class="elementor-widget-container">
          <div class="hentry">
            <h1 class="post-title">{{ $t(`productPage.${name}.title`)}}</h1>
          </div>
        </div>
      </div>
        <div class="elementor-widget-container">
          <p>
            {{ $t(`productPage.${name}.f1`)}}{{ $t(`productPage.${name}.f2`)}}
            
            
            <br/>
            <br/>
            {{ $t(`productPage.${name}.f3`)}}
            <br/>
            {{ $t(`productPage.${name}.f4`)}}
          </p>
        </div>
      </div>
    </div>
    <div
        class="elementor-element elementor-element-3c919e0 shake-full elementor-widget elementor-widget-mae-post-featured_image"
        data-id="3c919e0"
        data-element_type="widget"
        data-widget_type="mae-post-featured_image.default"
      >
        <div class="elementor-widget-container">
          <div class="post-media" id="productImg">
            <img
              id="oil-single"
              width="770"
              height="420"
              :src="require(`../../public/img/oil.png`)" :alt="name"
              class="attachment-full size-full wp-post-image lazy"
            />
          </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  data(){
    return{ 
      name: this.oil
    }
  },
  props: {
    oil: String,
}
};
</script>

<style lang="scss" scoped></style>
