<template>
  <div id="site-header-wrap">
    <div class="agrios-header agrios-container header-float">
      <div
        data-elementor-type="wp-post"
        data-elementor-id="1163"
        class="elementor elementor-1163"
      >
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-60679d8 elementor-section-content-middle elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="60679d8"
          data-element_type="section"
          data-settings='{"stretch_section":"section-stretched"}'
        >
          <div
            class="elementor-container elementor-column-gap-no"
            id="mobileNav"
          >
            <div
              class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-d55c631"
              data-id="d55c631"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-1528bdc elementor-widget__width-auto elementor-widget elementor-widget-mae-logo"
                  data-id="1528bdc"
                  data-element_type="widget"
                  data-widget_type="mae-logo.default"
                >
                  <div class="elementor-widget-container">
                    <div class="master-logo logo-image">
                      <router-link aria-label="logo" to="/">
                        <img
                          width="340"
                          height="103"
                          src="../../../public/img/logo.png"
                          
                          class="attachment-full size-full lazy logoHeader"
                          alt=""
                        />
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-fb28d19"
              data-id="fb28d19"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-00a4b21 align-center elementor-widget__width-auto elementor-hidden-tablet elementor-hidden-mobile elementor-widget elementor-widget-mae-menu"
                  data-id="00a4b21"
                  data-element_type="widget"
                  data-widget_type="mae-menu.default"
                >
                  <div class="elementor-widget-container">
                    <div class="agrios-menu-wrap">
                      <nav class="agrios-menu">
                        <ul id="menu-633edefa8617b" class="menu">
                          <!-- <li
                            id="menu-item-64"
                            class="menu-item menu-item-type-custom menu-item-object-custom menu-item-home current-menu-ancestor current-menu-parent menu-item-64"
                          >
                            <router-link to="/"
                              ><span>{{ $t("menu.home") }}</span></router-link
                            >
                          </li> -->
                          <li
                            id="menu-item-2499"
                            class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2499"
                          >
                            <a href="/#about"
                              ><span>{{ $t("menu.about") }}</span></a
                            >
                          </li>
                          <li
                            id="menu-item-71"
                            class="menu-item menu-item-type-custom menu-item-object-custom menu-item-71"
                          >
                            <a href="/#products"
                              ><span>{{ $t("menu.products") }}</span></a
                            >
                          </li>
                          <li
                            id="menu-item-72"
                            class="menu-item menu-item-type-custom menu-item-object-custom menu-item-72"
                          >
                            <a href="/#cooperation"
                              ><span>{{ $t("menu.cooperation") }}</span></a
                            >
                          </li>
                          <li
                            id="menu-item-2498"
                            class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2498"
                          >
                            <a href="/#contacts"
                              ><span>{{ $t("menu.contacts") }}</span></a
                            >
                          </li>
                          <li
                            id="menu-item-69 MYCLASs"
                            class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-69"
                          >
                            <a href="#" aria-current="page"
                              ><span>
                                {{ currentLanguage.toUpperCase() }}</span
                              ></a
                            >
                            <ul class="sub-menu">
                              <li
                                v-for="locale in filterdLocales"
                                :key="locale"
                                id="menu-item-2504"
                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-103 current_page_item menu-item-2504"
                              >
                                <a
                                  href="#"
                                  aria-current="page"
                                  @click.prevent="changeLocale(locale)"
                                  ><span>{{ locale.toUpperCase() }}</span></a
                                >
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-cd6a1e8 align-center align--mobileright elementor-hidden-desktop palign-left elementor-widget elementor-widget-mae-hamburger"
                  data-id="cd6a1e8"
                  data-element_type="widget"
                  data-widget_type="mae-hamburger.default"
                >
                  <div class="elementor-widget-container">
                    <div class="agrios-menu-wrap">
                      <div class="hamburger-menu-wrap">
                        <div
                          :class="[
                            'agrios-hamburger-icon',
                            { hide: menuDesktopOpened },
                          ]"
                          @click="navShow()"
                        >
                          <i aria-hidden="true" class="ci-menu"></i>
                        </div>

                        <div class="agrios-menu-panel">
                          <div
                            :class="[
                              'menu-panel-overlay',
                              { show: menuDesktopOpened },
                            ]"
                          ></div>
                          <div
                            :class="[
                              'menu-panel-wrap',
                              { menuShowed: menuDesktopOpened },
                            ]"
                          >
                            <div class="close-menu" @click="navShow()"></div>

                            <div class="menu-logo">
                              <router-link
                                aria-label="logo"
                                to="/"
                              >
                                <img width="340" height="103"
                                src="../../../public/img/logo.png"
                                 
                                class="attachment-full size-full lazy logoHeader" alt="" />
                              </router-link>
                            </div>

                            <ul id="menu-633edefa88fb0" class="menu">
                              <li
                                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-home current-menu-ancestor current-menu-parent menu-item-64"
                              >
                                <router-link to="/"
                                  ><span>{{
                                    $t("menu.home")
                                  }}</span></router-link
                                >
                              </li>
                              <li
                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2499"
                              >
                                <a href="/#about"
                                  ><span>{{ $t("menu.about") }}</span></a
                                >
                              </li>
                              <li
                                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-71"
                              >
                                <a href="/#products"
                                  ><span>{{ $t("menu.products") }}</span></a
                                >
                              </li>
                              <li
                                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-72"
                              >
                                <a href="/#cooperation"
                                  ><span>{{ $t("menu.cooperation") }}</span></a
                                >
                              </li>
                              <li
                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2498"
                              >
                                <a href="/#contacts"
                                  ><span>{{ $t("menu.contacts") }}</span></a
                                >
                              </li>
                              <li
                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-69"
                              >
                                <a href="#" aria-current="page"
                                  ><span>
                                    {{ currentLanguage.toUpperCase() }}</span
                                  ><span class="arrow"  @click.prevent="subMenuDesktop()"></span></a
                                >
                                <ul :class="['sub-menu', {'showSubMenu' : subDesktop}]">
                                  <li
                                    v-for="locale in filterdLocales"
                                    :key="locale"
                                    id="menu-item-2504"
                                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-103 current_page_item menu-item-2504"
                                  >
                                    <a
                                      href="#"
                                      aria-current="page"
                                      @click.prevent="changeLocale(locale)"
                                      ><span>{{
                                        locale.toUpperCase()
                                      }}</span></a
                                    >
                                  </li>
                                </ul>
                              </li>
                            </ul>

                            <div class="desc">
                              {{ $t("banner.first") }} {{ $t("banner.second") }} {{ $t("banner.third") }} {{ $t("banner.last") }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-e1d7a52"
              data-id="e1d7a52"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-3ff1fcf elementor-widget__width-auto elementor-hidden-tablet elementor-hidden-mobile shake-hover icon-has-bg elementor-widget elementor-widget-mae-cta"
                  data-id="3ff1fcf"
                  data-element_type="widget"
                  data-widget_type="mae-cta.default"
                >
                  <div class="elementor-widget-container">
                    <a class="master-cta" href="tel:0674322378">
                      <span class="content-wrap">
                        <span class="icon"
                          ><i aria-hidden="true" class="agi agi-phone-call"></i
                        ></span>
                        <span class="texts">
                          <span class="text">{{ $t("menu.call") }}</span>
                          <span class="phone">(067) 432-23-78</span>
                        </span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div
      :class="[
        { 'fixed-show': fixed },
        'agrios-header-fixed',
        'fixed-hide',
        'agrios-container',
      ]"
    >
      <div
        data-elementor-type="wp-post"
        data-elementor-id="1994"
        class="elementor elementor-1994"
      >
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-a7f518a elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="a7f518a"
          data-element_type="section"
          data-settings='{"stretch_section":"section-stretched","background_background":"classic"}'
        >
          <div class="elementor-container elementor-column-gap-no">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-12ca268"
              data-id="12ca268"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-4710ebe elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="4710ebe"
                  data-element_type="section"
                >
                  <div class="elementor-container elementor-column-gap-no">
                    <div
                      class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-a5b1a5e"
                      data-id="a5b1a5e"
                      data-element_type="column"
                    >
                      <div
                        class="elementor-widget-wrap elementor-element-populated"
                      >
                        <div
                          class="elementor-element elementor-element-3c9d19b elementor-widget__width-auto elementor-widget elementor-widget-mae-logo"
                          data-id="3c9d19b"
                          data-element_type="widget"
                          data-widget_type="mae-logo.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="master-logo logo-image">
                              <router-link aria-label="logo" to="/">
                                <img
                                  width="340"
                                  height="103"
                                  src="../../../public/img/logo.png"
                                  
                                  class="attachment-full size-full lazy logoHeader"
                                  alt=""
                                />
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-802c463"
                      data-id="802c463"
                      data-element_type="column"
                    >
                      <div
                        class="elementor-widget-wrap elementor-element-populated"
                      >
                        <div
                          class="elementor-element elementor-element-e1c864b align-right elementor-widget__width-auto elementor-hidden-tablet elementor-hidden-mobile elementor-widget elementor-widget-mae-menu"
                          data-id="e1c864b"
                          data-element_type="widget"
                          data-widget_type="mae-menu.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="agrios-menu-wrap">
                              <nav class="agrios-menu">
                                <ul id="menu-633edefa902df" class="menu">
                                  <li
                                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-home current-menu-ancestor current-menu-parent menu-item-64"
                                  >
                                    <router-link to="/"
                                      ><span>{{
                                        $t("menu.home")
                                      }}</span></router-link
                                    >
                                  </li>
                                  <li
                                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2499"
                                  >
                                    <a href="/#about"
                                      ><span>{{ $t("menu.about") }}</span></a
                                    >
                                  </li>
                                  <li
                                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-71"
                                  >
                                    <a href="/#products"
                                      ><span>{{ $t("menu.products") }}</span></a
                                    >
                                  </li>
                                  <li
                                    class="menu-item menu-item-type-custom menu-item-object-custommenu-item-72"
                                  >
                                    <a href="/#cooperation"
                                      ><span>{{
                                        $t("menu.cooperation")
                                      }}</span></a
                                    >
                                  </li>
                                  <li
                                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2498"
                                  >
                                    <a href="/#contacts"
                                      ><span>{{ $t("menu.contacts") }}</span></a
                                    >
                                  </li>
                                  <li
                                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-69"
                                  >
                                    <a href="#" aria-current="page"
                                      ><span>
                                        {{
                                          currentLanguage.toUpperCase()
                                        }}</span
                                      ></a
                                    >
                                    <ul class="sub-menu">
                                      <li
                                        v-for="locale in filterdLocales"
                                        :key="locale"
                                        id="menu-item-2504"
                                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-103 current_page_item menu-item-2504"
                                      >
                                        <a
                                          href="#"
                                          aria-current="page"
                                          @click.prevent="changeLocale(locale)"
                                          ><span>{{
                                            locale.toUpperCase()
                                          }}</span></a
                                        >
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </nav>
                            </div>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-87e4ddc align-center align--mobileright elementor-hidden-desktop elementor-widget__width-auto palign-left elementor-widget elementor-widget-mae-hamburger"
                          data-id="87e4ddc"
                          data-element_type="widget"
                          data-widget_type="mae-hamburger.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="agrios-menu-wrap">
                              <div class="hamburger-menu-wrap">
                                <div
                                  :class="[
                                    'agrios-hamburger-icon',
                                    { hide: menuMobileOpened },
                                  ]"
                                  @click="navMobileShow()"
                                >
                                  <i aria-hidden="true" class="ci-menu"></i>
                                </div>

                                <div class="agrios-menu-panel">
                                  <div
                                    :class="[
                                      'menu-panel-overlay',
                                      { show: menuMobileOpened },
                                    ]"
                                  ></div>
                                  <div
                                    :class="[
                                      'menu-panel-wrap',
                                      { menuShowed: menuMobileOpened },
                                    ]"
                                  >
                                    <div
                                      class="close-menu"
                                      @click="navMobileShow()"
                                    ></div>

                                    <div class="menu-logo">
                                      <router-link
                                        aria-label="logo"
                                        to="/"
                                      >
                                        <img
                                          width="340"
                                          height="103"
                                          src="../../../public/img/logo.png"
                                          
                                          class="attachment-full size-full lazy logoHeader"
                                          alt=""
                                        />
                                      </router-link>
                                    </div>

                                    <ul id="menu-633edefa91ad6" class="menu">
                                      <li
                                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-home current-menu-ancestor current-menu-parent menu-item-64"
                                      >
                                        <router-link to="/"
                                          ><span>{{
                                            $t("menu.home")
                                          }}</span></router-link
                                        >
                                      </li>
                                      <li
                                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2499"
                                      >
                                        <a href="#about"
                                          ><span>{{
                                            $t("menu.about")
                                          }}</span></a
                                        >
                                      </li>
                                      <li
                                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-71"
                                      >
                                        <a href="#products"
                                          ><span>{{
                                            $t("menu.products")
                                          }}</span></a
                                        >
                                      </li>
                                      <li
                                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-72"
                                      >
                                        <a href="#cooperation"
                                          ><span>{{
                                            $t("menu.cooperation")
                                          }}</span></a
                                        >
                                      </li>

                                      <li
                                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2498"
                                      >
                                        <a href="#contacts"
                                          ><span>{{
                                            $t("menu.contacts")
                                          }}</span></a
                                        >
                                      </li>
                                    </ul>
                                    <li
                                      class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-69"
                                    >
                                      <a href="#" aria-current="page"
                                        ><span>
                                          {{
                                            currentLanguage.toUpperCase()
                                          }}<span class="arrow"  @click.prevent="subMenuMobile()"></span></span
                                        ></a
                                      >
                                      <ul :class="['sub-menu', {'showSubMenu' : subMenu}]" >
                                        <li
                                          v-for="locale in filterdLocales"
                                          :key="locale"
                                          id="menu-item-2504"
                                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-103 current_page_item menu-item-2504"
                                        >
                                          <a
                                            href="#"
                                            aria-current="page"
                                            @click.prevent="
                                              changeLocale(locale)
                                            "
                                            ><span>{{
                                              locale.toUpperCase()
                                            }}</span></a
                                          >
                                        </li>
                                      </ul>
                                    </li>
                                    <div class="desc">
                                      Lorem ipsum dolor sit amet, consectetur
                                      adipiscing elit. Ut elit tellus, luctus
                                      nec ullamcorper mattis, pulvinar dapibus
                                      leo.
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderTest",
  data() {
    return {
      fixed: false,
      menuMobileOpened: false,
      menuDesktopOpened: false,
      subMenu: false,
      subDesktop: false,
      locales: ["ru", "ua", "en"],
    };
  },
  created() {
    window.addEventListener("scroll", this.fixedShow);
  },
  computed: {
    currentLanguage: function () {
      return this.$locale;
    },
    filterdLocales: function () {
      return this.locales.filter((item) => item !== this.$locale);
    },
  },
  methods: {
    changeLocale(locale) {
      this.$locale = locale;
    },
    fixedShow() {
      window.scrollY > 1000 ? (this.fixed = true) : (this.fixed = false);
    },
    navShow: function () {
      this.menuDesktopOpened = !this.menuDesktopOpened;
    },
    navMobileShow: function () {
      this.menuMobileOpened = !this.menuMobileOpened;
    },
    subMenuDesktop: function () {
      this.subDesktop = !this.subDesktop;
    },
    subMenuMobile: function () {
      this.subMenu = !this.subMenu;
    },
  },
};
</script>

<style lang="scss" scoped></style>
