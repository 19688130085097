<template>
  <footer class="agrios-footer footer">
    <div class="agrios-container">
      <div
        data-elementor-type="wp-post"
        data-elementor-id="919"
        class="elementor elementor-919"
      >
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-eb5b33f elementor-section-full_width elementor-section-stretched elementor-section-height-default elementor-section-height-default"
          data-id="eb5b33f"
          data-element_type="section"
          data-settings='{"stretch_section":"section-stretched","background_background":"classic"}'
        >
          <div class="elementor-container elementor-column-gap-no">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-3a7f176"
              data-id="3a7f176"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">

                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-element-ad1a8df elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="ad1a8df"
                  data-element_type="section"
                >
                  <div class="elementor-container elementor-column-gap-default">
                    <div
                      class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-af4cdfa"
                      data-id="af4cdfa"
                      data-element_type="column"
                    >
                      <div
                        class="elementor-widget-wrap elementor-element-populated"
                      >
                        <div
                          class="elementor-element elementor-element-097c18d elementor-widget elementor-widget-mae-logo"
                          data-id="097c18d"
                          data-element_type="widget"
                          data-widget_type="mae-logo.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="master-logo logo-image">
                              <router-link
                                to="/"
                              >
                                <img
                                  width="340"
                                  height="103"
                                  src="../../../public/img/logo.png"
                                  class="attachment-full size-full lazy"
                                  alt="logo"
                                />
                              </router-link>
                            </div>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-37575a0 elementor-widget elementor-widget-text-editor"
                          data-id="37575a0"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div class="elementor-widget-container">
                            <p>
                              {{ $t("banner.first") }} {{ $t("banner.second") }} {{ $t("banner.third") }} {{ $t("banner.last") }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-05b6e40"
                      data-id="05b6e40"
                      data-element_type="column"
                    >
                      <div
                        class="elementor-widget-wrap elementor-element-populated"
                      >
                        <div
                          class="elementor-element elementor-element-3cd9a57 elementor-widget elementor-widget-heading"
                          data-id="3cd9a57"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h2
                              class="elementor-heading-title elementor-size-default"
                            >
                              Explore
                            </h2>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-995bbb2 custom-divider elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                          data-id="995bbb2"
                          data-element_type="widget"
                          data-widget_type="divider.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-divider">
                              <span class="elementor-divider-separator"> </span>
                            </div>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-9cb2ef9 icon-default elementor-widget elementor-widget-mae-list"
                          data-id="9cb2ef9"
                          data-element_type="widget"
                          data-widget_type="mae-list.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="master-list">
                              <div class="master-icon-text list-item">
                                <div class="icon-wrap">
                                  <i
                                    aria-hidden="true"
                                    class="agi agi-leaf-solid"
                                  ></i>
                                </div>

                                <div class="content-wrap">
                                  <router-link to="/">{{ $t("menu.home") }} </router-link>
                                </div>
                              </div>
                              <div class="master-icon-text list-item">
                                <div class="icon-wrap">
                                  <i
                                    aria-hidden="true"
                                    class="agi agi-leaf-solid"
                                  ></i>
                                </div>

                                <div class="content-wrap">
                                  <a href="#about">{{ $t("menu.about") }} </a>
                                </div>
                              </div>
                              <div class="master-icon-text list-item">
                                <div class="icon-wrap">
                                  <i
                                    aria-hidden="true"
                                    class="agi agi-leaf-solid"
                                  ></i>
                                </div>

                                <div class="content-wrap">
                                  <a href="#products">{{ $t("menu.about") }}</a>
                                </div>
                              </div>
                              <div class="master-icon-text list-item">
                                <div class="icon-wrap">
                                  <i
                                    aria-hidden="true"
                                    class="agi agi-leaf-solid"
                                  ></i>
                                </div>

                                <div class="content-wrap">
                                  <a href="#cooperation">{{ $t("menu.cooperation") }}</a>
                                </div>
                              </div>
                              <div class="master-icon-text list-item">
                                <div class="icon-wrap">
                                  <i
                                    aria-hidden="true"
                                    class="agi agi-leaf-solid"
                                  ></i>
                                </div>

                                <div class="content-wrap">
                                  <a href="#contacts">{{ $t("menu.contacts") }}</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-0f340d2"
                      data-id="0f340d2"
                      data-element_type="column"
                    >
                      <div
                        class="elementor-widget-wrap elementor-element-populated"
                      >
                        <div
                          class="elementor-element elementor-element-c6e122a elementor-widget elementor-widget-heading"
                          data-id="c6e122a"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h2
                              class="elementor-heading-title elementor-size-default"
                            >
                            {{ $t("menu.contacts") }}
                            </h2>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-88d635f custom-divider elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                          data-id="88d635f"
                          data-element_type="widget"
                          data-widget_type="divider.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-divider">
                              <span class="elementor-divider-separator"> </span>
                            </div>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-11b7e8e icon-default elementor-widget elementor-widget-mae-icon-text"
                          data-id="11b7e8e"
                          data-element_type="widget"
                          data-widget_type="mae-icon-text.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="master-icon-text">
                              <div class="icon-wrap">
                                <i aria-hidden="true" class="ci ci-phone2"></i>
                              </div>

                              <div class="content-wrap"><a href="tel:0674322378">(067) 432-23-78</a></div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-c73877a e-transform icon-default elementor-widget elementor-widget-mae-icon-text"
                          data-id="c73877a"
                          data-element_type="widget"
                          data-settings='{"_transform_translateX_effect":{"unit":"px","size":3,"sizes":[]},"_transform_translateX_effect_tablet":{"unit":"px","size":"","sizes":[]},"_transform_translateX_effect_mobile":{"unit":"px","size":"","sizes":[]},"_transform_translateY_effect":{"unit":"px","size":"","sizes":[]},"_transform_translateY_effect_tablet":{"unit":"px","size":"","sizes":[]},"_transform_translateY_effect_mobile":{"unit":"px","size":"","sizes":[]}}'
                          data-widget_type="mae-icon-text.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="master-icon-text">
                              <div class="icon-wrap">
                                <i
                                  aria-hidden="true"
                                  class="ci ci-envelope1"
                                ></i>
                              </div>

                              <div class="content-wrap">
                                <a href="mailto:birkoff08@ukr.net">birkoff08@ukr.net</a>
                                
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-b0741ee icon-default elementor-widget elementor-widget-mae-icon-text"
                          data-id="b0741ee"
                          data-element_type="widget"
                          data-widget_type="mae-icon-text.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="master-icon-text">
                              <div class="icon-wrap">
                                <i aria-hidden="true" class="ci ci-pin"></i>
                              </div>

                              <div class="content-wrap">
                                {{ $t("address.first") }}
                                <br />
                                {{ $t("address.second") }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <div
                  class="elementor-element elementor-element-9affd65 elementor-absolute image-width-full elementor-widget elementor-widget-mae-gallery-stack"
                  data-id="9affd65"
                  data-element_type="widget"
                  data-settings='{"_position":"absolute"}'
                  data-widget_type="mae-gallery-stack.default"
                >
                  <div class="elementor-widget-container">
                    <div class="master-gallery-stack">
                      <div
                        class="master-fancy-image elementor-repeater-item-b7478e9"
                        data-top="0px"
                        data-parallax='{"x" : 50, "y" : 0, "smoothness" : 30}'
                        data-calcHeight="no"
                      >
                        <div class="image-wrap">
                          <img
                            width="789"
                            height="324"
                            src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20789%20324'%3E%3C/svg%3E"
                            data-src="./img/farm-03.webp"
                            class="attachment-full size-full lazy"
                            alt=""
                            data-srcset="./img/farm-03.webp 789w, ./img/farm-03-600x246.webp 600w, ./img/farm-03-300x123.webp 300w, ./img/farm-03-768x315.webp 768w"
                            data-sizes="(max-width: 789px) 100vw, 789px"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-ef9f949 elementor-section-stretched elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="ef9f949"
          data-element_type="section"
          data-settings='{"stretch_section":"section-stretched","background_background":"classic"}'
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-0de8f73"
              data-id="0de8f73"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-d84738e align--mobilecenter align-left elementor-widget elementor-widget-mae-copyright"
                  data-id="d84738e"
                  data-element_type="widget"
                  data-widget_type="mae-copyright.default"
                >
                  <div class="elementor-widget-container">
                    <div class="master-copyright">© All Copyright 2023</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss" scoped></style>
