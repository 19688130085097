<template>
  <div id="product-page">
    <div id="featured-title" class="clearfix centered lazy">
      <div class="agrios-container clearfix">
        <div class="inner-wrap">
          <div id="breadcrumbs">
            <div class="breadcrumbs-inner">
              <div class="breadcrumb-trail">
                <router-link class="home" to="/">{{
                  $t("menu.home")
                }}</router-link>
                <span>{{ $t("products.titleCereals") }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /#featured-title -->

    <div id="main-content" class="site-main clearfix productPage" style="">
      <div id="content-wrap" class="agrios-container">
        <div id="site-content" class="site-content clearfix">
          <div id="inner-content" class="inner-content-wrap">
            <div
              data-elementor-type="wp-post"
              data-elementor-id="2466"
              class="elementor elementor-2466"
            >
              <section
                class="elementor-section elementor-top-section elementor-element elementor-element-f50ea9a elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="f50ea9a"
                data-element_type="section"
              >
                <!-- <div
                  :class="[
                    'agrios-hamburger-icon inner-icon-hamb',
                    { hide: open },
                  ]"
                  @click="catShow()"
                >
                  <i aria-hidden="true" class="ci-menu"></i>
                  {{ $t("productPage.categories") }}
                </div> -->
                <div class="elementor-container elementor-column-gap-default">
                  <div
                    :class="[
                      'elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-14cf8a7 elementor-hidden-tablet',
                      { hideContent: !open },
                    ]"
                    data-id="14cf8a7"
                    data-element_type="column"
                  >
                    <div
                      class="elementor-widget-wrap elementor-element-populated"
                    >
                      <section
                        class="elementor-section elementor-inner-section elementor-element elementor-element-e57fe60 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="e57fe60"
                        data-element_type="section"
                      >
                        <div
                          class="elementor-container elementor-column-gap-default"
                        >
                          <div
                            class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-00883be"
                            data-id="00883be"
                            data-element_type="column"
                            data-settings='{"background_background":"classic"}'
                          >
                            <div
                              class="elementor-widget-wrap elementor-element-populated"
                            >
                              <div
                                class="elementor-element elementor-element-ece4761 elementor-widget elementor-widget-heading"
                                data-id="ece4761"
                                data-element_type="widget"
                                data-widget_type="heading.default"
                              >
                                <div class="elementor-widget-container">
                                  <h2
                                    class="elementor-heading-title elementor-size-default"
                                  >
                                    {{ $t("productPage.categories") }}
                                  </h2>
                                  <div
                                    class="close-menu"
                                    @click="navShow()"
                                  ></div>
                                </div>
                              </div>

                              <div
                                v-for="item in CerealsCategories"
                                :key="item.key"
                                :class="[
                                  'elementor-element elementor-element-e63c3c1 widget-link line-no elementor-widget elementor-widget-mae-link',
                                ]"
                                data-id="e63c3c1"
                                data-element_type="widget"
                                data-widget_type="mae-link.default"
                              >
                                <div class="elementor-widget-container">
                                  <a
                                    class="master-link icon-right"
                                    href="#"
                                    @click.prevent="activeTab(item)"
                                  >
                                    <span>{{
                                      $t(`productPage.${item.name}.title`)
                                    }}</span>
                                    <span class="icon ci-right-arrow2"></span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>

                    </div>
                  </div>
                  <CerealsSingle
                    v-for="item in CerealsCategories"
                    :muchka="item.muchka"
                    :key="item.key"
                    :size="item.small"
                    :flour="item.name"
                    v-show="item.active"
                  />
                </div>
              </section>
                                    <section
                        class="elementor-section elementor-inner-section elementor-element elementor-element-20eeebb elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="20eeebb"
                        data-element_type="section"
                      >
                        <div
                          class="elementor-container elementor-column-gap-default"
                        >
                          <div
                            class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-8deff70"
                            data-id="8deff70"
                            data-element_type="column"
                            data-settings='{"background_background":"classic"}'
                          >
                            <div
                              class="elementor-widget-wrap elementor-element-populated"
                            >
                              <div
                                class="elementor-element elementor-element-de91cae align-center heading-default elementor-widget elementor-widget-mae-headings"
                                data-id="de91cae"
                                data-element_type="widget"
                                data-widget_type="mae-headings.default"
                              >
                                <div class="elementor-widget-container">
                                  <div class="master-heading sep-none">
                                    <h2 class="main-heading">
                                      {{ $t("help.title") }}
                                    </h2>

                                    <div class="sub-heading">
                                      {{ $t("help.subtitle") }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="elementor-element elementor-element-8995b5b elementor-widget elementor-widget-text-editor"
                                data-id="8995b5b"
                                data-element_type="widget"
                                data-widget_type="text-editor.default"
                              >
                                <div class="elementor-widget-container">
                                  <a class="phone-btn" href="tel:0674322378"
                                    >(067) 432-23-78</a
                                  >
                                </div>
                              </div>
                              <div
                                class="elementor-element elementor-element-b56fbfc align-center elementor-widget elementor-widget-mae-button"
                                data-id="b56fbfc"
                                data-element_type="widget"
                                data-widget_type="mae-button.default"
                              >
                                <div class="elementor-widget-container">
                                  <a
                                    class="master-button btn-accent icon-none medium btn-hover-2"
                                    href="/#contacts"
                                  >
                                    <span class="inner">
                                      <span class="content-base">
                                        <span class="text">{{
                                          $t("help.btn")
                                        }}</span>
                                      </span>

                                      <span class="content-hover">
                                        <span class="text">{{
                                          $t("help.btn")
                                        }}</span>
                                      </span>
                                    </span>

                                    <span class="bg-hover"></span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
            </div>
          </div>
          <!-- /#inner-content -->
        </div>
        <!-- /#site-content -->
      </div>
      <!-- /#content-wrap -->
    </div>
  </div>
</template>

<script>
import CerealsSingle from "../components/CerealsSingle.vue";
export default {
  muchka: false,
  name: "Product",
  data() {
    return {
      isActive: true,
      open: false,
      CerealsCategories: [
        { muchka: false,small: false, name: "serealsCorn", key: 1, active: true },
        { muchka: false,small: false, name: "serealsPea", key: 2, active: false },
        { muchka: false,small: false, name: "serealsPeaGreen", key: 3, active: false },
        { muchka: false,small: false, name: "serealsBarley", key: 4, active: false },
        { muchka: false,small: false, name: "artek", key: 15, active: false },
        { muchka: false,small: false, name: "riceLong", key: 16, active: false },
        { muchka: false,small: false, name: "pearlGroats", key: 5, active: false },
        { muchka: false,small: false, name: "serealsWheat", key: 6, active: false },
        { muchka: false,small: false, name: "serealsGroats", key: 7, active: false },
        { muchka: false,small: false, name: "serealsGroatsBarley", key: 8, active: false },
        { muchka: false,small: true, name: "kukurudza", key: 9, active: false },
        { muchka: false,small: true, name: "gorox", key: 10, active: false },
        { muchka: true,small: true, name: "muchka", key: 11, active: false },
        { muchka: true,small: true, name: "muchkayachk", key: 12, active: false },
        { muchka: false,small: true, name: "pcheno", key: 13, active: false },
        { muchka: false,small: true, name: "yacmen", key: 14, active: false },
      ],
    };
  },
  methods: {
    activeTab: function (el) {
      for (let i = 0; i < this.CerealsCategories.length; i++) {
        if (this.CerealsCategories[i].key === el.key) {
          this.CerealsCategories[i].active = true;
        } else {
          this.CerealsCategories[i].active = false;
        }
      }
      this.isActive = !this.isActive;
    },
    catShow: function () {
      this.open = !this.open;
    },
  },
  components: {
    CerealsSingle,
  },
};
</script>

<style lang="scss" scoped></style>
