<template>
  <div
    data-elementor-type="wp-page"
    data-elementor-id="1710"
    id="about"
    class="elementor elementor-1710"
  >
    <section
      class="elementor-section elementor-top-section elementor-element elementor-element-969508c elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-id="969508c"
      data-element_type="section"
    >
      <div class="elementor-container elementor-column-gap-no">
        <div
          class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d4afe89"
          data-id="d4afe89"
          data-element_type="column"
        >
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="elementor-element elementor-element-6306627 elementor-widget elementor-widget-spacer"
              data-id="6306627"
              data-element_type="widget"
              data-widget_type="spacer.default"
            >
              <div class="elementor-widget-container">
                <div class="elementor-spacer">
                  <div class="elementor-spacer-inner"></div>
                </div>
              </div>
            </div>
            <section
              class="elementor-section elementor-inner-section elementor-element elementor-element-40d2e07 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="40d2e07"
              data-element_type="section"
            >
              <div class="elementor-container elementor-column-gap-default">
                <div
                  class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-7ec6b46"
                  data-id="7ec6b46"
                  data-element_type="column"
                >
                  <div
                    class="elementor-widget-wrap elementor-element-populated"
                  >
                    <div
                      class="elementor-element elementor-element-10ea00d heading-default elementor-widget elementor-widget-mae-headings"
                      data-id="10ea00d"
                      data-element_type="widget"
                      data-widget_type="mae-headings.default"
                    >
                      <div class="elementor-widget-container">
                        <div class="master-heading sep-none">
                          <div class="pre-heading">
                            <span class="line"><span class="inner"></span></span
                            >{{ $t("about.about") }}
                          </div>

                          <h2 class="main-heading">
                            {{ $t("about.about1") }}
                          </h2>

                          <div class="sub-heading">
                            {{ $t("about.about2") }}
                            <!-- <br /> -->
                            {{ $t("about.about21") }}
                            <br />  
                            {{ $t("about.about22") }}
                            <br />
                            {{ $t("about.about3") }}
                            <br />
                            {{ $t("about.about31") }}
                            <br />
                            {{ $t("about.about33") }}
                            <br />
                            {{ $t("about.about34") }}
                            <br />
                            {{ $t("about.about35") }}
                            <br/>
                          {{ $t("about.about4") }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="elementor-element elementor-element-c75cd5b icon-position-left hover-effect-none elementor-widget elementor-widget-mae-icon-box"
                      data-id="c75cd5b"
                      data-element_type="widget"
                      data-widget_type="mae-icon-box.default"
                    >
                      <div class="elementor-widget-container">
                        <div class="master-icon-box">
                          <div class="inner">
                            <div class="icon-wrap">
                              <div class="master-icon">
                                <i class="agi agi-agriculture-1"></i>
                              </div>
                            </div>
                            <div class="text-wrap">
                              <h3 class="headline-2">
                                {{ $t("about.about5") }}
                              </h3>
                            </div>
                          </div>

                          <div class="bg-static"></div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="elementor-element elementor-element-b2d67fa elementor-widget__width-auto elementor-widget elementor-widget-mae-button"
                      data-id="b2d67fa"
                      data-element_type="widget"
                      data-widget_type="mae-button.default"
                    >
                      <div class="elementor-widget-container">
                        <a
                          class="master-button btn-accent icon-none medium btn-hover-2"
                          href="#contacts"
                        >
                          <span class="inner">
                            <span class="content-base">
                              <span class="text">{{ $t("btns.order") }}</span>
                            </span>

                            <span class="content-hover">
                              <span class="text">{{ $t("btns.order") }}</span>
                            </span>
                          </span>

                          <span class="bg-hover"></span>
                        </a>
                      </div>
                    </div>
                    <div
                      class="elementor-element elementor-element-bc69ab8 elementor-widget__width-auto shake-hover icon-has-bg elementor-widget elementor-widget-mae-cta"
                      data-id="bc69ab8"
                      data-element_type="widget"
                      data-widget_type="mae-cta.default"
                    >
                      <div class="elementor-widget-container">
                        <a class="master-cta" href="tel:0674322378">
                          <span class="content-wrap">
                            <span class="icon"
                              ><i
                                aria-hidden="true"
                                class="agi agi-phone-call"
                              ></i
                            ></span>
                            <span class="texts">
                              <span class="text">{{ $t("menu.call") }}</span>
                              <span class="phone">(067) 432-23-78</span>
                            </span>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-45167af"
                  data-id="45167af"
                  data-element_type="column"
                >
                  <div
                    class="elementor-widget-wrap elementor-element-populated"
                  >
                    <div
                      class="elementor-element elementor-element-91b0fa6 image-width-full elementor-widget elementor-widget-mae-gallery-stack"
                      data-id="91b0fa6"
                      data-element_type="widget"
                      data-widget_type="mae-gallery-stack.default"
                    >
                      <div class="elementor-widget-container">
                        <div class="master-gallery-stack">
                          <div
                            class="master-fancy-image elementor-repeater-item-f00bcf0"
                            data-top="0px"
                            data-calcHeight="no"
                          >
                            <div class="image-wrap">
                              <img
                                width="571"
                                height="570"
                                src="../../../public/img/about-1.jpg"
                                class="attachment-full size-full lazy aboutImg"
                                alt="about"
                              />
                            </div>
                          </div>
                          <div
                            class="master-text elementor-repeater-item-b15cd4f default"
                          >
                            <div class="inner">
                              <div class="box-exp">
                                <span class="number">20<sub>+</sub></span>
                                <span class="text">{{ $t("btns.years") }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div
              class="elementor-element elementor-element-cddcab4 elementor-widget elementor-widget-spacer"
              data-id="cddcab4"
              data-element_type="widget"
              data-widget_type="spacer.default"
            >
              <div class="elementor-widget-container">
                <div class="elementor-spacer">
                  <div class="elementor-spacer-inner"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
