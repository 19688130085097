<template>
  <div class="Homepage">
    <div id="main-content" class="site-main clearfix" style="">
      <div id="content-wrap" class="agrios-container">
        <div id="site-content" class="site-content clearfix">
          <div id="inner-content" class="inner-content-wrap">
            <article
              class="page-content post-103 post-1710 page type-page status-publish hentry"
            >
              <div
                data-elementor-type="wp-page"
                data-elementor-id="103"
                class="elementor elementor-103"
              >
                <Banner />
                <Products/>
                <About />
                <Advantages />
                <Purchase/>
              </div>
            </article>
            <Contacts/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "../components/homePage/Banner.vue";
import Advantages from '../components/homePage/Advantages.vue'
import About from '../components/homePage/About.vue'
import Products from '../components/homePage/Products.vue'
import Purchase from '../components/homePage/Purchase.vue'
import Contacts from '../components/homePage/Contacts.vue'
export default {
  name: "Home",
  components: {
    Banner,
    Advantages,
    About,
    Products,
    Purchase,
    Contacts,
  },
};
</script>

<style></style>
